<script setup>
import PhaserContainer from '@/components/PhaserContainer'
</script>

<script>
export default {
  name: 'GameSetup',
  props: ['setup']
}
</script>

<template>
  <Suspense>
    <PhaserContainer setup="setup" />

    <template #fallback>
      <div class="placeholder">
        Preparing the raffle ...
      </div>
    </template>
  </Suspense>
</template>

<style lang="scss" scoped>
.placeholder {
  font-size: 2rem;
  font-family: 'Courier New', Courier, monospace;
}
</style>
