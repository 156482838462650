
import {defineStore} from 'pinia'

export const mainStore = defineStore('main', {

  state: () => {
    return {
      runtime: 30,
      names: null,
      participants: 10,
      moon: false
    }
  }
})